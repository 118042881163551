import Image from 'next/image';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useTheme } from '@/app/components/Theme';
import BannerButton from '@/app/components/BannerButton';
import { cn } from '@/utils/helper';
import Footer from '@/app/components/Footer';

const heroImages = [
  {
    text: 'Dream big, work hard: Your success is within reach!',
    image: '/home/hero-1.jpg',
    color: 'blue',
  },
  {
    text: 'Fuel your body, ignite your passion: Nutrition is key!',
    image: '/home/hero-2.jpg',
    color: 'green',
  },
  {
    text: 'If you put it on the calendar, it will happen!',
    image: '/home/hero-3.jpg',
    color: 'yellow',
  },
  {
    text: 'Every step counts: Progress is made one day at a time!',
    image: '/home/hero-4.jpg',
    color: 'red',
  },
  {
    text: 'Believe in yourself: Your potential is limitless and bright!',
    image: '/home/hero-5.jpg',
    color: 'purple',
  },
  {
    text: 'Stay committed, stay strong: Your journey is worth it!',
    image: '/home/hero-1.jpg',
    color: 'cyan',
  },
  {
    text: 'Push your limits: Growth happens outside your comfort zone!',
    image: '/home/hero-2.jpg',
    color: 'brown',
  },
  {
    text: 'Consistency is key: Small efforts lead to big results!',
    image: '/home/hero-3.jpg',
    color: 'orange',
  },
  {
    text: 'Embrace the challenge: Every obstacle is an opportunity!',
    image: '/home/hero-4.jpg',
    color: 'red',
  },
  {
    text: 'Your journey is unique: Celebrate every milestone you achieve!',
    image: '/home/hero-5.jpg',
    color: 'purple',
  },
];

const features = [
  {
    title: 'Align Your Workouts with Your Daily Activities.',
    description:
      'Focus on the present by effectively matching your workouts with your daily activities. This approach not only helps you stay committed to your fitness goals but also allows you to evaluate your progress in real-time. By structuring your workouts for the week ahead, you can ensure that each session is purposeful and aligned with your current lifestyle, making every effort count.',
    imageLight: '/home/feature-2.jpg',
    imageDark: '/home/feature-2-dark.jpg',
  },
  {
    title: 'Engage with your virtual coach for comprehensive support.',
    description:
      'Your virtual coach is at your service, ready to address any inquiries you may have. Whether you seek guidance on crafting personalized workout routines, require in-depth nutritional analysis, or wish to review and enhance your previous workouts, your coach is equipped to assist you. Experience a wealth of knowledge and support tailored to elevate your fitness journey to new heights.',
    imageLight: '/home/feature-3.gif',
    imageDark: '/home/feature-3-dark.gif',
  },
  {
    title: 'Empower Your Fitness Journey with AI Collaboration.',
    description:
      'Take charge of your fitness journey with a user-friendly interface that puts you in control. Behind the scenes, multiple AI agents work together to adapt to your preferences, ensuring that your daily workouts are tailored to your needs. Modify your plan, add notes, or introduce new workouts, and watch as your virtual coach seamlessly adjusts to keep you on track.',
    imageLight: '/home/feature-1.gif',
    imageDark: '/home/feature-1-dark.gif',
  },
  {
    title: 'Sync your workouts with your calendar and devices.',
    description:
      'Trainme offers a seamless integration with leading smartwatches, including Garmin and Apple Watch, to elevate your fitness experience. This innovative platform synchronizes your workouts with your device, ensuring you receive timely reminders for your exercise regimen. With real-time notifications, you will remain well-informed about upcoming sessions, progress updates, and any modifications to your plan. Trainme is dedicated to facilitating a smooth and engaging fitness journey, guiding you towards your next significant milestones.',
    imageLight: '/home/feature-4.png',
    imageDark: '/home/feature-4.png',
  },
];

const HomePublic = () => {
  const { isDarkMode } = useTheme();

  const sliderSettings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 7000,
    speed: 300,
    pauseOnHover: false,
  };

  const size = 600;

  return (
    <article className='w-full h-screen overflow-y-auto text-gray-800 '>
      <section className='w-full'>
        <Slider {...sliderSettings}>
          {heroImages.map((image, index) => (
            <div
              className='w-full h-72 md:h-96 lg:h-128 xl:h-160 relative'
              key={index}
            >
              <Image
                src={image.image}
                alt={image.text}
                width={1200}
                height={500}
                className='w-full h-full absolute inset-0 object-cover opacity-90 bg-gray-200 dark:bg-gray-800'
              />
              <div className={cn(['relative z-10 h-full container mx-auto'])}>
                <h2
                  className='h2 absolute transform -translate-y-1/2 text-gray-100 text-[2.2em] font-bold'
                  style={{
                    top: '50%',
                    left: '10%',
                    transform: 'translateY(-50%)',
                  }}
                >
                  {image.text}
                </h2>
                <div
                  className='absolute transform -translate-x-1/2 -translate-y-1/2'
                  style={{ top: '50%', left: '75%' }}
                >
                  <BannerButton btnColor={image.color} />
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </section>
      <section className='flex-1 container mx-auto max-w-screen-2xl text-gray-900 dark:text-gray-300 py-4'>
        {features.map((feature, index) => (
          <div
            key={index}
            className={cn([
              'grid grid-cols-1 md:grid-cols-2 gap-24 items-center text-[1.2em] leading-[1.8em] my-24',
            ])}
          >
            <div
              className={cn([
                index % 2 === 0 ? 'order-1' : 'order-2',
                index === 3 ? 'h-1/2 w-1/2 mx-auto self-start' : 'h-full',
              ])}
            >
              <Image
                src={isDarkMode ? feature.imageDark : feature.imageLight}
                alt={feature.title}
                layout='responsive'
                width={size}
                height={size}
              />
            </div>
            <div className={cn([index % 2 === 0 ? 'order-2' : 'order-1'])}>
              <h2 className='h2'>{feature.title}</h2>
              <p>{feature.description}</p>
            </div>
          </div>
        ))}
      </section>
      <Footer />
    </article>
  );
};

export default HomePublic;
