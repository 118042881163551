import React from 'react';

const Footer: React.FC = () => {
  const links = [
    {
      title: 'Privacy Policy',
      description:
        'Review our privacy policy to understand how we manage your data.',
      url: '/customer/privacy',
    },
    {
      title: 'Customer Support',
      description: 'Receive assistance with your account or any issues.',
      url: '/customer/support',
    },
    {
      title: 'Contact Us',
      description: 'Reach out to us with any questions or feedback.',
      url: '/customer/contact',
    },
    {
      title: 'Terms of Service',
      description:
        'Read our terms of service to understand how we manage your data.',
      url: '/customer/terms',
    },
  ];

  return (
    <footer className='bg-gray-400 dark:bg-gray-700 text-white p-4 mb-8'>
      <div className='container mx-auto max-w-screen-2xl flex items-center justify-between gap-4'>
        {links.map((link, index) => (
          <div key={index} className='p-2'>
            <a
              href={link.url}
              className='hover:underline'
              title={link.description}
            >
              {link.title}
            </a>
          </div>
        ))}
        &copy; {new Date().getFullYear()} Naozz. All rights reserved.
      </div>
    </footer>
  );
};

export default Footer;
