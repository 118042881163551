"use client";

import HomePublic from "@/app/components/HomePublic";
import { useUser } from "@clerk/nextjs";

const Page = () => {
  const { user } = useUser();

  if (user) return <HomePublic />;
  else return <HomePublic />;
};

export default Page;
